.pagination {
  position:relative;
  float:left;
  width:100%;
  margin-top:48px;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(48px + 6 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:54px;
  }

  ul {
    display:flex; flex-direction:row; align-items:center; justify-content:center;
    text-align:center; font-size:0;

    li {
      width:auto;
      margin:0 2px;

      span, a {
        position:relative;
        display:block; float:left;
        width:34px; height:34px;
        font-size:16px; line-height:34px; text-align:center; color:color(white); font-family:$font_poppins; font-weight:500;
      }
      span {
        font-weight:700;
        background-color:color(red);
      }
      a {
        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:1px solid color(white);
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            &:before {
              border:1px solid color(red);
            }
          }
        }
      }

      &.nav-prev, &.nav-next {
        a {
          position:relative;

          &:before {
            content:none;
          }

          i {
            position:absolute; left:50%; top:50%;
            width:16px; height:16px;
            font-size:16px; line-height:16px; color:color(white);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
          }
        }

        &.disabled {
          a {
            cursor:default;
            pointer-events:none;

            i {
              opacity:0.5;
            }
          }
        }
      }
      &.nav-prev {
        margin-right:22px;
      }
      &.nav-next {
        margin-left:22px;
      }
    }
  }
}
