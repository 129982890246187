.text-article {
  > div {
    margin-bottom:24px;

    @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(24px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-bottom:32px;
    }

    &:last-child {
      margin-bottom:0;
    }

    > * {
      margin-bottom:24px;

      @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(24px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
          margin-bottom:32px;
      }

      &:last-child {
        margin-bottom:0;
      }
    }
    h4, .h4, p {
      float:none;
    }
    picture {
      width:100%;
      float:left;

      &.right {
        @include media-breakpoint-up(md) {
          float:right;
          width:50%;
          padding-left:calc(32px + 23 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl) {
          img {
            width:125%;
            margin-right:-25%;
          }
        }
        @include media-breakpoint-up(xl-1440) {
            padding-left:55px;
        }
      }
      &.left {
        @include media-breakpoint-up(md) {
          float:right;
          width:50%;
          padding-right:calc(32px + 23 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl) {
          img {
            width:125%;
            margin-left:-25%;
          }
        }
        @include media-breakpoint-up(xl-1440) {
            padding-right:55px;
        }
      }
    }
  }
}

.side-article {
  display:block; float:left;
  width:100%;
  margin-top:24px;

  @include media-breakpoint-up(md) {
    margin-top:calc(14px + 14 * ((100vw - 768px) / 672));
  }
  @include media-breakpoint-up(xl-1440) {
      margin-top:28px;
  }

  &.right {
    padding-left:0;

    @include media-breakpoint-up(md) {
      padding-left:calc(16px + 30 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
        padding-left:46px;
    }
  }
  &.left {
    padding-right:0;

    @include media-breakpoint-up(md) {
      padding-right:calc(16px + 30 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
        padding-right:46px;
    }
  }
  p {
    margin-top:10px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(10px + 4 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:14px;
    }
  }
  ul {
    margin-top:20px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(20px + 20 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:40px;
    }

    li {
      width:100%;
      padding-right:30px; margin-top:12px;

      @include media-breakpoint-up(sm) {
        width:50%;
      }
      @include media-breakpoint-up(md) {
        width:100%;
      }
      @include media-breakpoint-up(lg) {
        width:50%;
      }

      p, a {
        width:auto;
        margin:0;
      }
      p {
        margin-right:16px;

        @include media-breakpoint-up(xs-375) {
          margin-right:calc(16px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-right:20px;
        }
      }
      a {
        font-size:13px; line-height:24px; letter-spacing:0.78px; font-weight:700;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        background-image:linear-gradient(color(red), color(red));
        background-repeat:no-repeat;
        background-position:bottom left;
        background-size:100% 2px;

        @include media-breakpoint-up(xs-375) {
          font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(24px + 8 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          letter-spacing:0.9px;

          &:hover {
            color:color(red);
          }
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:15px; line-height:32px;
        }

        &.phone {
          @include media-breakpoint-up(lg) {
            background-size:0% 2px;
          }
        }
      }
    }
  }
}
