.header {
  position:fixed; left:0; top:0;
  width:100%; height:90px;
  padding:17px 12px 5px 12px;
  background-color:color(black);
  z-index:100;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(lg) {
    height:122px;
    padding:12px 12px 6px 12px;
  }

  .header-logo {
    display:block; float:left;
    height:68px; width:auto;
    margin:0;

    @include media-breakpoint-up(lg) {
      height:98px;
      margin:6px 0 0 52px;
    }

    img {
      height:100%; width:auto;
    }
  }
  .mobile-menu {
    position:relative;
    float:right;
    width:26px; height:26px;
    margin-top:10px;

    i {
      position:absolute; top:50%; right:0;
      float:left;
      color:color(white);
      @include prefix(transform, translateY(-50%), webkit moz o ms);

      &.icon-az-menu-mobile {
        display:block;
        width:26px; height:26px;
        font-size:26px; line-height:26px;
      }
      &.icon-az-close {
        display:none;
        width:14px; height:14px;
        font-size:14px; line-height:14px;
      }
    }
  }
  .header-search {
    padding-bottom:14px; margin-top:30px;
    border-bottom:3px solid color(red);

    fieldset {
      position:relative;
      height:24px;

      button {
        position:absolute; right:0; top:0;
        display:none;
        width:38px; height:24px;
        background-color:transparent;
        z-index:3;
      }
      label {
        position:absolute; left:50%; top:0;
        width:auto;
        padding:0 38px 0 0;
        font-size:16px; line-height:24px; color:color(white); letter-spacing:0.8px; text-align:left; text-transform:uppercase; white-space:nowrap;
        cursor:text;
        z-index:2;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        @include prefix(transform, translateX(-50%), webkit moz o ms);

        &:before {
          content:$icon-az-search;
          position:absolute; right:2px; top:2px;
          width:20px; height:20px;
          font-size:20px; line-height:20px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        }
      }
      input {
        position:relative;
        height:0px;
        padding:0 38px 0 0;
        font-size:16px; line-height:24px; color:color(white); letter-spacing:0.8px;
        z-index:1;
      }
    }

    &.focus {
      fieldset {
        button {
          display:block;
        }
        label {
          width:100%!important;
          z-index:1;
        }
        input {
          height:24px;
          z-index:2;
        }
      }
    }
  }
  .header-search-mobile {
    margin-top:10px;

    fieldset {
      position:relative;
      padding-right:30px;

      button {
        position:absolute; right:-4px; top:0;
        display:none;
        width:30px; height:100%;
        z-index:3;
      }
      a:not(.close) {
        position:absolute; right:0; top:50%;
        width:20px; height:20px;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        z-index:2;

        i {
          display:block; float:left;
          width:20px; height:20px;
          font-size:20px; line-height:20px; color:color(white);
        }
      }
      a.close {
        position:absolute; left:calc(-66% - 14px); top:0;
        display:none;
        width:34px; height:100%;
        z-index:2;

        i {
          position:absolute; left:0; top:50%;
          width:16px; height:16px;
          margin-top:2px;
          font-size:16px; line-height:16px; color:color(grey);
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
      }
      input {
        position:relative;
        float:right;
        width:0%;
        padding:2px 0;
        border-bottom:2px solid color(red);
        font-size:16px; line-height:24px; color:color(white); letter-spacing:0.8px;
        z-index:1;
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
    }
  }

  .header-menu {
    display:block; float:left;
    width:calc((((100% - 60px) / 4) * 3) + 40px);
    padding-left:20px; margin-top:22px;
    @include prefix(transform, translateX(20px), webkit moz o ms);

    ul {
      float:right;
      width:auto;

      li {
        width:auto;

        a {
          position:relative;
          display:block; float:left;
          width:auto;
          padding:10px 12px;
          font-size:13px; line-height:18px; letter-spacing:0.65px;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          &:before {
              content:'';
              position:absolute; left:0; bottom:0;
              width:0%; height:2px;
              background-color:color(red);
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            padding:10px calc(12px + 6 * ((100vw - 992px) / 448));

            &:hover {
              color:color(white);

              &:before {
                  width:100%;
              }
            }
          }
          @include media-breakpoint-up(xl-1440) {
              padding:10px 18px;
          }
        }

        &.active {
          a {
            background-color:color(red);
          }
        }
      }
    }
  }
  .header-lang {
    position:relative;
    display:block; float:left;
    width:calc(((100% - 60px) / 4) + 20px);
    padding-left:20px;

    > a {
      position:relative;
      display:block; float:left;
      width:auto;
      padding:0 23px;
      font-size:14px; line-height:20px; letter-spacing:1.12px; font-weight:600; color:color(white)!important;

      &:after {
        content:$icon-az-arrow-down;
        position:absolute; right:0; top:50%;
        width:7px; height:7px;
        font-size:7px; line-height:7px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
    }
    > ul {
      position:absolute; top:-9999px; left:50%;
      display:flex; flex-direction:column;
      width:auto;
      opacity:0;
      @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
      @include prefix(transform, translateX(-50%), webkit moz o ms);

      li {
        position:relative;
        width:auto;
        margin-top:4px;

        a {
          position:relative;
          display:block; float:left;
          width:100%;
          padding:6px 20px;
          font-size:12px; line-height:18px; letter-spacing:0.6px; color:color(white)!important; text-align:center;
          background-color:color(black);
          z-index:2;

          &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            border:1px solid color(white);
            z-index:1;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            &:hover {
              &:before {
                border:1px solid color(grey);
              }
            }
          }
        }
        &.active {
          a {
            background-color:color(red);
            cursor:default;
            pointer-events:none;

            &:before {
              content:none;
            }
          }
        }
      }
    }

    &.open {
      > a {
        &:after {
          content:$icon-az-arrow-up;
        }
      }
      > ul {
        top:100%;
        opacity:1;
      }
    }
  }

  &.open-search-mobile {
    .header-logo {
      display:none;
    }
    .header-search-mobile {
      fieldset {
        a.close {
          display:block;
        }
        button {
          display:block;
        }
        input {
          width:166%;
        }
      }
    }
  }
}

.side-menu {
  position:fixed; right:0; bottom:0;
  display:block;
  width:100%;
  z-index:98;
  background-color:color(black);
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(lg) {
    top:50%; bottom:inherit;
    width:auto;
    background-color:transparent;
    @include prefix(transform, translateY(-50%), webkit moz o ms);
  }

  ul {
    position:relative;
    display:flex; flex-direction:row; align-items:flex-start;
    width:100%;
    font-size:0;

    @include media-breakpoint-up(lg) {
      flex-direction:column; align-items:flex-end;
      width:auto;

      &:before {
        content:'';
        position:absolute; right:0; top:0;
        width:3px; height:100%;
        background-color:color(white);
      }
    }

    li {
      position:relative;
      width:33.3%;
      z-index:2;

      @include media-breakpoint-up(lg) {
        width:auto;
        margin-bottom:10px;
        padding-right:10px;
      }

      &:last-child {
        margin-bottom:0;
      }

      a {
        position:relative;
        display:block; float:right;
        width:100%;
        padding:10px 12px;
        font-size:14px; line-height:18px; font-weight:500; letter-spacing:1.68px; text-align:center; color:color(white)!important;
        background-color:transparent;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          width:auto;
          padding:10px 12px;
          font-size:15px; line-height:18px; font-weight:500; letter-spacing:1.8px;

          &:hover {
            color:color(red)!important;
          }
        }
      }

      &.active {
        @include media-breakpoint-up(lg) {
          padding-right:22px;

          &:before {
            content:'';
            position:absolute; right:0; top:0;
            width:3px; height:100%;
            background-color:color(red);
          }
        }

        a {
          color:color(white)!important;
          background-color:color(red);
        }
      }
    }
  }
}

.product-layer {
  position:fixed; left:0; top:0;
  display:none;
  width:100%; height:100vh;
  padding:140px 0;
  background-color:color(black);
  z-index:97;
  @include prefix(transition, all .4s ease-out, webkit moz o ms);
  @include prefix(transform, translateY(-100%), webkit moz o ms);

  @include media-breakpoint-up(lg) {
    display:block;
  }

  .product-layer-content {
    position:relative;
    float:left;
    width:100%;
    text-align:center;

    h3, .h3 {
      font-size:26px; line-height:36px; text-align:left;

      @include media-breakpoint-up(xs-375) {
          font-size:calc(26px + 0 * ((100vw - 375px) / 1065)); line-height:calc(36px + 0 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
          font-size:26px; line-height:36px;
      }
    }
    .close {
      position:absolute; right:-46px; top:0;
      width:36px; height:36px;

      i {
        position:absolute; left:50%; top:50%;
        width:14px; height:14px;
        font-size:14px; line-height:14px; color:color(white);
        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          i {
            color:color(grey);
          }
        }
      }
    }
    > div {
      float:left;
      width:calc(100% + 20px);
      margin-top:20px; margin-left:-10px; margin-right:-10px;

      > ul {
        width:20%;
        padding-left:10px; padding-right:10px;

        > li {
          position:relative;
          padding:20px 0 20px 28px;

          &:after {
            content:'';
            position:absolute; left:0; bottom:0;
            width:100%; height:1px;
            background-color:color(white);
            opacity:0.2;
          }
          > a {
            font-size:18px; line-height:25px; font-weight:500; letter-spacing:0.06px; text-align:left;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(red);
              }
            }

            &.sub {
              position:absolute; left:0; top:20px;
              width:28px; height:calc(100% - 40px);

              i {
                position:absolute; left:0; top:8px;
                width:9px; height:9px;
                font-size:9px; line-height:9px; color:color(white);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                &:hover {
                  i {
                    color:color(red);
                  }
                }
              }
            }
          }

          > ul {
            display:none;
            margin-bottom:14px;

            > li {
              margin-top:16px;

              a {
                font-size:14px; line-height:20px; font-weight:400; letter-spacing:0.84px; color:color(grey);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:color(white);
                  }
                }
              }
            }
          }
        }
      }
    }
    .btn {
      margin-top:55px;
    }
  }
}

.product-layer-open {
  @include media-breakpoint-up(lg) {
    .side-menu {
      ul {
        li {
          padding-right:10px!important;

          a {
            background-color:transparent!important;
          }

          &.product {
            padding-right:22px!important;

            a {
              color:color(white)!important;

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                border:2px solid color(white);
              }
            }
          }
        }
      }
    }

    .product-layer {
      @include prefix(transform, translateY(0%), webkit moz o ms);
    }
  }
}

.menu-layer {
  position:fixed; left:0; top:0;
  width:100%; height:100vh;
  padding:90px 0 0 0;
  background-color:color(black);
  z-index:99;
  @include prefix(transition, all .4s ease-out, webkit moz o ms);
  @include prefix(transform, translateY(-100%), webkit moz o ms);

  @include media-breakpoint-up(lg) {
    display:none!important;
  }

  .menu-layer-content {
    float:left;
    width:100%; height:calc(100vh - 90px);
    padding:0 12px;
    overflow:auto;

    .mobile-menu {
      position:relative;
      display:block; float:left;
      width:100%;
      z-index:2;

      ul {
        li {
          margin-bottom:15px;
          text-align:center; font-size:0;

          a {
            display:inline-block; float:none;
            width:100%;
            text-align:center; font-size:14px; line-height:20px; letter-spacing:0.7px; color:color(white);
          }
          .header-lang-mobile {
            position:relative;
            display:inline-block; float:none;
            width:160px;
            margin-top:8px;

            > a {
              display:block; float:left;
              width:100%;
              padding:5px 10px;
              font-size:12px; letter-spacing:0.48px; text-align:center;
              background-color:color(dark);
              @include prefix(border-radius, 16px, webkit moz o ms);

              i {
                display:inline;
                width:6px; height:20px;
                margin-left:8px;
                font-size:6px; line-height:20px; color:color(white);
              }
            }
            > ul {
              position:absolute; left:50%; top:-9999px;
              width:100%;
              opacity:0;
              background-color:color(dark);
              @include prefix(transform, translateX(-50%), webkit moz o ms);
              @include prefix(transition, opacity .3s ease-out, webkit moz o ms);
              @include prefix(border-radius, 16px, webkit moz o ms);
              @include prefix(border-top-left-radius, 0, webkit moz o ms);
              @include prefix(border-top-right-radius, 0, webkit moz o ms);

              li {
                position:relative;
                margin:0; padding-top:1px;

                &:before {
                  content:'';
                  position:absolute; left:10px; top:0;
                  width:calc(100% - 20px); height:1px;
                  opacity:0.2;
                  background-color:color(grey);
                }

                a {
                  display:block; float:left;
                  padding:12px 10px;
                  font-size:12px; line-height:20px; letter-spacing:0.48px; text-align:center;
                }
              }
            }

            &.open {
              > a {
                @include prefix(border-bottom-left-radius, 0, webkit moz o ms);
                @include prefix(border-bottom-right-radius, 0, webkit moz o ms);
              }
              > ul {
                top:100%;
                opacity:1;
              }
            }
          }
        }
      }
    }

    .menu-layer-list {
      position:relative;
      float:left;
      width:100%;
      margin-top:30px;
      text-align:center; font-size:0;
      z-index:1;

      > ul {
        padding-bottom:12px;
        border-bottom:1px solid color(grey-med);
        text-align:center; font-size:0;
        margin-bottom:30px;

        li {
          display:inline-block; float:none;
          width:auto;
          margin:0 15px;

          a {
            display:block; float:none;
            width:auto;
            font-size:16px; line-height:22px; color:color(white); letter-spacing:0.32px; font-weight:500; text-transform:uppercase;
            opacity:0.4;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
          &.active {
            a {
              opacity:1;
              cursor:default;
              pointer-events:none;
            }
          }
        }
      }

      > div {
        display:none; float:left;
        width:100%;
        padding-bottom:80px;
        text-align:center;

        &.active {
          display:block;
        }

        > ul {
          margin-top:20px;
          padding:0 28px;

          li {
            position:relative;
            padding:16px 20px;

            &:before {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:1px;
              opacity:0.5;
              background-color:color(grey-med);
            }

            &:first-child {
              &:before {
                content:none;
              }
            }

            > a:not(.sub) {
              display:block; float:left;
              width:100%;
              font-size:16px; line-height:24px; color:color(white); text-align:center; font-weight:500; letter-spacing:0.32px;
            }
            > a.sub {
              position:absolute; right:0; top:0;
              width:20px; height:100%;

              &:after {
                content:$icon-az-arrow-down;
                position:absolute; left:0; top:23px;
                width:10px; height:10px;
                font-size:10px; line-height:10px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              }
            }

            > ul {
              display:none;
              width:calc(100% + 40px);
              margin-left:-20px; margin-right:-20px; margin-bottom:2px;

              > li {
                padding:16px 0;

                &:first-child {
                  padding-top:10px;
                }
                > a {
                  font-size:14px!important; line-height:20px!important; text-align:left!important; letter-spacing:0.7px!important; font-weight:400!important;
                }
              }
            }

            &.open {
              > a.sub {
                &:after {
                  content:$icon-az-arrow-up;
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu-layer-open {
  .header {
    .mobile-menu {
      i {
        &.icon-az-menu-mobile {
          display:none;
        }
        &.icon-az-close {
          display:block;
        }
      }
    }
  }

  .menu-layer {
    @include prefix(transform, translateY(0%), webkit moz o ms);
  }
}
