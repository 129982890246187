body {
  background-color:color(black);
}

.container {
  width:100%; max-width:1418px;
  padding-right:10px; padding-left:10px;

  .row {
    margin-right:-10px; margin-left:-10px;

    > div {
      padding-right:10px; padding-left:10px;
    }

    &.more-gutter {
      @include media-breakpoint-up(lg) {
        margin-right:-14px; margin-left:-14px;

        > div {
          padding-right:14px; padding-left:14px;
        }
      }
    }
  }
}

.main-content {
  float:left;
  width:100%;
  padding-top:90px;

  @include media-breakpoint-up(lg) {
    padding-top:122px;
  }
}

section {
  position:relative;
  float:left;
  width:100%;
  margin-top:60px;
  padding-left:12px; padding-right:12px;
  z-index:1;
  overflow:hidden;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(60px + 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:100px;
  }
}

.mt-small {
  margin-top:32px!important;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(32px + 16 * ((100vw - 375px) / 1065))!important;
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:48px!important;
  }
}

picture, img {
  display:block; float:left;
  width:100%; height:auto;
}

.overflow-visible {
    overflow:visible;
}
.overflow-hidden {
    overflow:hidden;
}

ul, ol {
  display:block; float:left;
  width:100%;
  padding:0; margin:0;
  list-style:none;

  li {
    display:block; float:left;
    width:100%;
    text-align:left;
  }
}

a {
    text-decoration:none!important; color:inherit;
}
* {
    outline:0!important; outline:none!important;
}
