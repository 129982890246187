.catalog-box {
  float:left;
  width:100%;
  margin-top:34px;

  @include media-breakpoint-up(lg) {
      margin-top:0;
  }

  h3, .h3 {
    margin-bottom:20px;
    text-align:center;
  }

  > div {
    position:relative;
    display:flex; flex-direction:row; align-items:center; justify-content:center;
    float:left;
    width:100%;
    text-align:center; font-size:0;

    @include media-breakpoint-up(md) {
      padding:0 160px 0 calc(80px + 80 * ((100vw - 768px) / 672));
    }
    @include media-breakpoint-up(xl-1440) {
        padding:0 160px;
    }

    picture {
      display:inline-block; float:none;
      width:160px;
      border:1px solid color(grey-dark);
      margin-right:36px;

      @include media-breakpoint-up(xs-375) {
          width:calc(160px + 66 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(md) {
          margin-right:0;
      }
      @include media-breakpoint-up(xl-1440) {
          width:226px;
      }
    }
    .btn.download {
      @include media-breakpoint-up(md) {
        position:absolute; right:0; top:50%;
        @include prefix(transform, translateY(calc(-42px - 8 * ((100vw - 768px) / 672))), webkit moz o ms);
      }
      @include media-breakpoint-up(xl-1440) {
        @include prefix(transform, translateY(-50px), webkit moz o ms);
      }
    }
  }
}

.catalog-list {
  margin-bottom:-46px;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(-46px - 54 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:-100px;
  }

  .catalog {
    display:block; float:left;
    width:100%;
    margin-bottom:46px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(46px + 54 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:100px;
    }

    a {
      display:block; float:left;
      width:100%;
      text-align:center;

      .content {
        position:relative;
        float:left;
        width:100%;
        margin-bottom:18px;

        @include media-breakpoint-up(xs-375) {
          margin-bottom:calc(18px + 14 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-bottom:32px;
        }

        h4, .h4 {
          text-transform:inherit; font-weight:500; color:color(red); text-align:center; letter-spacing:0;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
      }

      .image {
        position:relative;
        float:left;
        width:100%;
        text-align:center; font-size:0;

        picture {
          position:relative;
          display:inline-block; float:none;
          height:225px; width:auto;
          border:1px solid color(grey-dark);
          z-index:1;

          img {
            height:100%; width:auto;
          }

          &:before {
            content:'';
            position:absolute; left:0; bottom:0;
            width:100%; height:30px;
            opacity:0.4;
            z-index:2;
            background:rgb(11,11,11);
            background:linear-gradient(180deg, rgba(11,11,11,0) 0%, rgba(11,11,11,1) 100%);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(xs-375) {
              height:calc(30px + 10 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
              height:40px;
            }
          }
        }
      }
      .btn {
        position:relative;
        z-index:3;
        @include prefix(transform, translateY(-15px), webkit moz o ms);
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .image {
            picture{
              &:before {
                height:100%;
              }
            }
          }
        }
      }
    }
  }
}
