.btn {
  position:relative;
  display:inline-block; float:none;
  width:auto;
  padding:10px 28px; border:0 none;
  font-size:12px; line-height:20px; letter-spacing:0.9px; color:color(white); text-transform:uppercase; font-weight:bold;
  background-color:transparent;
  @include prefix(border-radius, 100px, webkit moz o ms);
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    border:2px solid color(white);
    opacity:0.8;
    @include prefix(border-radius, 100px, webkit moz o ms);
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }

  @include media-breakpoint-up(xs-375) {
    padding:calc(10px + 5 * ((100vw - 375px) / 1065)) calc(28px + 6 * ((100vw - 375px) / 1065));
    font-size:calc(12px + 1 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
    letter-spacing:1.17px;

    &:before {
      border:3px solid color(white);
    }

    &:hover {
      color:color(red);
      background-color:color(white);

      &:before {
        opacity:1;
      }
    }
  }
  @include media-breakpoint-up(xl-1440) {
    padding:15px 34px;
    font-size:13px;
  }

  &.btn-big {
    padding:13px 28px;
    font-size:14px; letter-spacing:0.7px;

    &:before {
      border:3px solid color(white);
    }

    @include media-breakpoint-up(xs-375) {
      padding:calc(13px + 5 * ((100vw - 375px) / 1065)) calc(28px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      letter-spacing:1.26px;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:18px 38px;
    }
  }

  &.btn-small {
    padding:9px 18px;
    font-size:10px; line-height:16px; letter-spacing:0.9px;

    &:before {
      border:2px solid color(white);
    }

    @include media-breakpoint-up(xs-375) {
      padding:calc(9px + 1 * ((100vw - 375px) / 1065)) 18px;
    }
    @include media-breakpoint-up(xl-1440) {
      padding:10px 18px;
    }
  }

  &.btn-full {
    padding:8px 25px;
    font-size:10px; line-height:18px; letter-spacing:0.9px; color:color(red);
    background-color:color(grey-light);

    &:before {
      border:2px solid color(white);
      opacity:0;
    }

    @include media-breakpoint-up(xs-375) {
      padding:calc(8px + 2 * ((100vw - 375px) / 1065)) calc(25px + 3 * ((100vw - 375px) / 1065));
      font-size:calc(10px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
      letter-spacing:1.08px;

      &:hover {
        background-color:color(white);

        &:before {
          opacity:0.8;
        }
      }
    }
    @include media-breakpoint-up(xl-1440) {
      padding:10px 28px;
      font-size:12px;
    }
  }
  &.btn-red {
    color:color(white);
    background-color:color(red);

    &:before {
      content:none;
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(red);
        background-color:color(white);
      }
    }
  }
  &.download {
    margin-top:38px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(38px + 17 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:55px;
    }

    &:after {
      content:$icon-az-download;
      position:absolute; left:50%; top:-38px;
      width:23px; height:23px;
      font-size:23px; line-height:23px; color:color(grey-light); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transform, translateX(-50%), webkit moz o ms);
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        top:calc(-38px - 17 * ((100vw - 375px) / 1065));
        width:calc(23px + 7 * ((100vw - 375px) / 1065)); height:calc(23px + 7 * ((100vw - 375px) / 1065));
        font-size:calc(23px + 7 * ((100vw - 375px) / 1065)); line-height:calc(23px + 7 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        top:-55px;
        width:30px; height:30px;
        font-size:30px; line-height:30px;
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        &:after {
          top:-45px;
        }
      }
    }
  }
  &.upload {
    padding-left:50px;

    @include media-breakpoint-up(xs-375) {
      padding-left:calc(50px + 15 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      padding-left:65px;
    }

    &:after {
      content:$icon-az-plus;
      position:absolute; top:50%; left:25px;
      width:14px; height:14px;
      font-size:14px; line-height:14px; color:(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);
      @include prefix(transform, translateY(-50%), webkit moz o ms);

      @include media-breakpoint-up(xs-375) {
        left:calc(25px + 10 * ((100vw - 375px) / 1065));
        width:calc(14px + 2 * ((100vw - 375px) / 1065)); height:calc(14px + 2 * ((100vw - 375px) / 1065));
        font-size:calc(14px + 2 * ((100vw - 375px) / 1065)); line-height:calc(14px + 2 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        left:35px;
        width:16px; height:16px;
        font-size:16px; line-height:16px;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        &:after {
          color:color(red);
        }
      }
    }
  }
}

a {
  @include media-breakpoint-up(lg) {
    &:hover {
      .btn {
        color:color(red);
        background-color:color(white);

        &:before {
          opacity:1;
        }
        &.btn-full {
          background-color:color(white);

          &:before {
            opacity:0.8;
          }
        }
        &.download {
          &:after {
            top:-45px;
          }
        }
      }
    }
  }
}
