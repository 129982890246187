.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

    a, &.highlight-box {
        .image {
            &:before {
                -webkit-animation-duration: 2s;
                  animation-duration: 2s;
                  -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                -webkit-animation-duration: 2s;
                  animation-duration: 2s;
                  -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;
            }
        }
    }
    &.side-image {
        &:before {
            -webkit-animation-duration: 2s;
              animation-duration: 2s;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
        }
    }
}
figure {
    &.animated {
        &:before {
            -webkit-animation-duration: 2s;
              animation-duration: 2s;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
        }
    }
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.round-element, .back-circle {
    &.animated {
        svg {
            opacity:1;
            stroke-dasharray:2000;
            stroke-dashoffset:2000;
            animation:circle 3s ease-out forwards;
        }
        > div {
            &:after {
                animation:lineWidth 0.8s ease-out forwards;
            }
        }
    }
}
.animated {
    .round-icon {
        svg {
            opacity:1!important;
            stroke-dasharray:2000;
            stroke-dashoffset:2000;
            animation:circle 3s ease-out forwards;
        }
    }
}
.intro-text {
    &.animated {
        .image {
            svg {
                opacity:1;
                stroke-dasharray:2000;
                stroke-dashoffset:2000;
                animation:circle 3s ease-out forwards;
            }
        }
    }
}


.delay00  {
    animation-delay:0s!important;
}
.delay10  {
    animation-delay:0.10s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.10s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.10s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.10s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.10s;
        }
        > div {
            &:after {
                animation-delay:0.10s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.10s;
        }
    }
}
.delay20  {
    animation-delay:0.20s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.20s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.20s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.20s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.20s;
        }
        > div {
            &:after {
                animation-delay:0.20s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.20s;
        }
    }
}
.delay30  {
    animation-delay:0.30s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.30s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.30s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.30s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.30s;
        }
        > div {
            &:after {
                animation-delay:0.30s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.30s;
        }
    }
}
.delay40  {
    animation-delay:0.40s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.40s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.40s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.40s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.40s;
        }
        > div {
            &:after {
                animation-delay:0.40s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.40s;
        }
    }
}
.delay50  {
    animation-delay:0.50s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.50s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.50s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.50s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.50s;
        }
        > div {
            &:after {
                animation-delay:0.50s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.50s;
        }
    }
}
.delay60  {
    animation-delay:0.60s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.60s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.60s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.60s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.60s;
        }
        > div {
            &:after {
                animation-delay:0.60s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.60s;
        }
    }
}
.delay70  {
    animation-delay:0.70s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.70s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.70s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.70s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.70s;
        }
        > div {
            &:after {
                animation-delay:0.70s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.70s;
        }
    }
}
.delay80  {
    animation-delay:0.80s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.80s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.80s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.80s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.80s;
        }
        > div {
            &:after {
                animation-delay:0.80s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.80s;
        }
    }
}
.delay90  {
    animation-delay:0.90s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:0.90s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:0.90s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:0.90s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:0.90s;
        }
        > div {
            &:after {
                animation-delay:0.90s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:0.90s;
        }
    }
}
.delay100 {
    animation-delay:1.00s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.00s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.00s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.00s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.00s;
        }
        > div {
            &:after {
                animation-delay:1.00s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.00s;
        }
    }
}
.delay110  {
    animation-delay:1.10s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.10s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.10s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.10s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.10s;
        }
        > div {
            &:after {
                animation-delay:1.10s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.10s;
        }
    }
}
.delay120  {
    animation-delay:1.20s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.20s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.20s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.20s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.20s;
        }
        > div {
            &:after {
                animation-delay:1.20s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.20s;
        }
    }
}
.delay130  {
    animation-delay:1.30s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.30s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.30s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.30s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.30s;
        }
        > div {
            &:after {
                animation-delay:1.30s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.30s;
        }
    }
}
.delay140  {
    animation-delay:1.40s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.40s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.40s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.40s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.40s;
        }
        > div {
            &:after {
                animation-delay:1.40s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.40s;
        }
    }
}
.delay150  {
    animation-delay:1.50s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.50s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.50s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.50s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.50s;
        }
        > div {
            &:after {
                animation-delay:1.50s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.50s;
        }
    }
}
.delay160  {
    animation-delay:1.60s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.60s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.60s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.60s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.60s;
        }
        > div {
            &:after {
                animation-delay:1.60s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.60s;
        }
    }
}
.delay170  {
    animation-delay:1.70s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.70s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.70s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.70s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.70s;
        }
        > div {
            &:after {
                animation-delay:1.70s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.70s;
        }
    }
}
.delay180  {
    animation-delay:1.80s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.80s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.80s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.80s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.80s;
        }
        > div {
            &:after {
                animation-delay:1.80s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.80s;
        }
    }
}
.delay190  {
    animation-delay:1.90s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:1.90s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:1.90s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:1.90s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:1.90s;
        }
        > div {
            &:after {
                animation-delay:1.90s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:1.90s;
        }
    }
}
.delay200 {
    animation-delay:2.00s;

    a, &.highlight-box {
        .image {
            &:before {
                animation-delay:2.00s;
            }
        }
    }
    &.event-cover, &.story-cover, &.side-video {
        picture {
            &:before {
                animation-delay:2.00s;
            }
        }
    }
    &.side-image {
        &:before {
            animation-delay:2.00s;
        }
    }
    &.round-element, &.back-circle {
        svg {
            animation-delay:2.00s;
        }
        > div {
            &:after {
                animation-delay:2.00s;
            }
        }
    }
    .round-icon {
        svg {
            animation-delay:2.00s;
        }
    }
}

figure {
    &.delay10  {
        &:before {
            animation-delay:0.10s;
        }
    }
    &.delay20  {
        &:before {
            animation-delay:0.20s;
        }
    }
    &.delay30  {
        &:before {
            animation-delay:0.30s;
        }
    }
    &.delay40  {
        &:before {
            animation-delay:0.40s;
        }
    }
    &.delay50  {
        &:before {
            animation-delay:0.50s;
        }
    }
    &.delay60  {
        &:before {
            animation-delay:0.60s;
        }
    }
    &.delay70  {
        &:before {
            animation-delay:0.70s;
        }
    }
    &.delay80  {
        &:before {
            animation-delay:0.80s;
        }
    }
    &.delay90  {
        &:before {
            animation-delay:0.90s;
        }
    }
    &.delay100 {
        &:before {
            animation-delay:1.00s;
        }
    }
    &.delay110  {
        &:before {
            animation-delay:1.10s;
        }
    }
    &.delay120  {
        &:before {
            animation-delay:1.20s;
        }
    }
    &.delay130  {
        &:before {
            animation-delay:1.30s;
        }
    }
    &.delay140  {
        &:before {
            animation-delay:1.40s;
        }
    }
    &.delay150  {
        &:before {
            animation-delay:1.50s;
        }
    }
    &.delay160  {
        &:before {
            animation-delay:1.60s;
        }
    }
    &.delay170  {
        &:before {
            animation-delay:1.70s;
        }
    }
    &.delay180  {
        &:before {
            animation-delay:1.80s;
        }
    }
    &.delay190  {
        &:before {
            animation-delay:1.90s;
        }
    }
    &.delay200 {
        &:before {
            animation-delay:2.00s;
        }
    }
}

.x10  { animation-duration:0.10s!important; }
.x20  { animation-duration:0.20s!important; }
.x30  { animation-duration:0.30s!important; }
.x40  { animation-duration:0.40s!important; }
.x50  { animation-duration:0.50s!important; }
.x60  { animation-duration:0.60s!important; }
.x70  { animation-duration:0.70s!important; }
.x80  { animation-duration:0.80s!important; }
.x90  { animation-duration:0.90s!important; }
.x100 { animation-duration:1.00s!important; }


/* Reset opacity */

.animate-scroll {
    opacity:0;
}


/* Line width */

@-webkit-keyframes lineWidth {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes lineWidth {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.lineWidth {
  -webkit-animation-name: lineWidth;
  animation-name: lineWidth;
}

/* Fade in */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/* Fade in right */

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/* Fade in left */

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/* Fade in up */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* Fade in down */

@-webkit-keyframes fadeInDown {
  from {
    opacity:1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity:0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity:1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity:0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/* Fade in zoom */

@-webkit-keyframes fadeInZoom {
  from {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes fadeInZoom {
  from {
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  to {
    opacity:1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fadeInZoom {
  -webkit-animation-name: fadeInZoom;
  animation-name: fadeInZoom;
}

/* Slide in left */

@-webkit-keyframes slideInLeft {
  from {
      opacity:1;
    -webkit-transform:translate3d(-100%, 0, 0);
    transform:translate3d(-100%, 0, 0);
  }

  to {
      opacity:1;
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
      opacity:1;
    -webkit-transform:translate3d(-100%, 0, 0);
    transform:translate3d(-100%, 0, 0);
  }

  to {
      opacity:1;
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

/* Slide in right */

@-webkit-keyframes slideInRight {
  from {
      opacity:1;
    -webkit-transform:translate3d(100%, 0, 0);
    transform:translate3d(100%, 0, 0);
  }

  to {
      opacity:1;
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
      opacity:1;
    -webkit-transform:translate3d(100%, 0, 0);
    transform:translate3d(100%, 0, 0);
  }

  to {
      opacity:1;
    -webkit-transform:translate3d(0, 0, 0);
    transform:translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

/* Reduce height */

@-webkit-keyframes reduceH {
  from {
      height:100%;
  }

  to {
      height:0%;
  }
}

@keyframes reduceH {
  from {
      height:100%;
  }

  to {
      height:0%;
  }
}

.reduceH {
    a, &.highlight-box {
        .image {
            &:before {
              -webkit-animation-name: reduceH;
              animation-name: reduceH;
            }
        }
    }

    &.event-cover, &.story-cover, &.side-video, &.team {
        picture {
            &:before {
              -webkit-animation-name: reduceH;
              animation-name: reduceH;
            }
        }
    }
    &.side-image {
        &:before {
          -webkit-animation-name: reduceH;
          animation-name: reduceH;
        }
    }
}

figure {
    &.reduceH {
        &:before {
          -webkit-animation-name: reduceH;
          animation-name: reduceH;
        }
    }
}

/* Ring bell */

@-webkit-keyframes ringBell {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@keyframes ringBell {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

.ringBell {
  -webkit-animation-name: ringBell;
  animation-name: ringBell;
}

/* Float */

@-webkit-keyframes floatUp {
    0% {
        transform:translate(-50%,-50%)
    }
    50% {
        transform:translate(-50%,calc(-50% - 10px))
    }
    100% {
        transform:translate(-50%,-50%)
    }
}

@keyframes floatUp {
    0% {
        transform:translate(-50%,-50%)
    }
    50% {
        transform:translate(-50%,calc(-50% - 10px))
    }
    100% {
        transform:translate(-50%,-50%)
    }
}

@-webkit-keyframes floatDown {
    0% {
        transform:translate(-50%,-50%)
    }
    50% {
        transform:translate(-50%,calc(-50% + 10px))
    }
    100% {
        transform:translate(-50%,-50%)
    }
}

@keyframes floatDown {
    0% {
        transform:translate(-50%,-50%)
    }
    50% {
        transform:translate(-50%,calc(-50% + 10px))
    }
    100% {
        transform:translate(-50%,-50%)
    }
}

/* Circle */

@-webkit-keyframes circle {
    to {
        stroke-dashoffset:0;
    }
}

@keyframes circle {
    to {
        stroke-dashoffset:0;
    }
}
