.breadcrumb {
  display:none; float:left;
  width:100%;
  margin:10px 0 0 0;
  text-align:center; font-size:0;

  @include media-breakpoint-up(md) {
    display:block;
  }

  ul {
    display:inline-block; float:none;
    width:auto;

    li {
      position:relative;
      width:auto;
      padding-right:18px; margin-right:12px;

      &:after {
        content:'';
        position:absolute; right:0; top:8px;
        width:6px; height:1px;
        background-color:color(white);
      }

      &:last-child {
        padding-right:0; margin-right:0;

        &:after {
          content:none;
        }
      }

      a, span {
        display:block; float:left;
        width:auto;
        font-size:12px; line-height:18px; color:color(white); letter-spacing:0.72px; font-style:italic;
      }
      a {
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(grey);
          }
        }
      }
      span {
        font-weight:700;
      }
    }
  }
}

.section-head {
  display:flex; float:left; flex-direction:column; align-items:center;
  width:100%;
  text-align:center;

  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top:28px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(28px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:38px;
    }
  }
  h5, .h5 {
    display:inline; float:none;
    width:auto;
    margin-top:0; margin-bottom:-12px;
    background-image:linear-gradient(color(white), color(white));
    background-repeat:no-repeat;
    background-position:bottom left;
    background-size:100% 3px;

    @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(-12px - 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-bottom:-20px;
    }
  }
  p {
    margin-top:10px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(10px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:14px;
    }
  }
  .btn {
    margin-top:34px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(34px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:46px;
    }
  }

  .product-bottom {
    display:flex; flex-direction:column; justify-content:center; align-items:center;
    margin-top:14px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(14px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(md) {
      flex-direction:row;
      margin-bottom:-20px;
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:26px;
    }

    a {
      margin:20px 26px 0 26px;

      @include media-breakpoint-up(md) {
        margin:20px 46px;
      }
    }
    .product-download {
      position:relative;
      padding:10px 42px 10px 45px;
      font-size:14px; line-height:26px; text-transform:uppercase; color:color(red); font-weight:700;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      i {
        position:absolute; left:0; top:50%;
        width:36px; height:36px;
        font-size:36px; line-height:36px; color:color(white);
        @include prefix(transform, translateY(-50%), webkit moz o ms);
      }
      &:after {
        content:$icon-az-download;
        position:absolute; right:0; top:50%;
        width:30px; height:30px;
        font-size:30px; line-height:30px; color:color(grey-light); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          width:calc(30px + 0 * ((100vw - 375px) / 1065)); height:calc(30px + 0 * ((100vw - 375px) / 1065));
          font-size:calc(30px + 0 * ((100vw - 375px) / 1065)); line-height:calc(30px + 0 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          width:30px; height:30px;
          font-size:30px; line-height:30px;
        }
      }
      @include media-breakpoint-up(lg) {
        &:hover {
          color:color(white);
        }
      }
    }

    &.m-small {
      a {
        margin:20px 10px 0 10px;

        @include media-breakpoint-up(md) {
          margin:20px 10px;
        }
      }
    }
  }
}
.section-title {
  display:block; float:left;
  width:100%;
  text-align:center;

  @include media-breakpoint-up(md) {
    text-align:left;
  }

  @include media-breakpoint-up(lg) {
    padding-left:52px;
  }

  p {
    margin-top:8px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(8px + 10 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:18px;
    }
  }
  .btn {
    float:left;
    margin-top:34px;
  }
}

.bottom-btn {
  margin-top:14px;
  text-align:center;

  @include media-breakpoint-up(xs-375) {
    margin-top:calc(14px + 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-top:26px;
  }

  a {
    margin:20px 14px 0 14px!important;
  }
}
