.map-wrapper {
  float:left;
  width:100%;
  text-align:center;

  .map {
    float:left;
    width:100%;
  }
  .btn {
    margin-top:24px;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(24px + 8 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:32px;
    }
  }
}
