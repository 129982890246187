input, textarea, select {
    @include prefix(border-radius, 0!important, webkit moz o ms);
    @include prefix(appearance, none!important, webkit moz o ms);
}

form {
  display:block; float:left;
  width:100%;

  fieldset {
    display:block; float:left;
    width:100%;

    input, button, textarea {
      display:block; float:left;
      width:100%;
      padding:0; margin:0; border:0 none;
      background-color:transparent;
    }
  }

  &.contact-form {
    margin-top:-28px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(-28px - 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:-42px;
    }

    .row {
      margin-right:-14px; margin-left:-14px;

      @include media-breakpoint-up(md) {
        padding-right:calc(-14px - 46 * ((100vw - 768px) / 672)); padding-left:calc(-14px - 46 * ((100vw - 768px) / 672));
      }
      @include media-breakpoint-up(xl-1440) {
          padding-right:-60px; padding-left:-60px;
      }

      > div {
        padding-right:14px; padding-left:14px;

        @include media-breakpoint-up(md) {
          padding-right:calc(14px + 46 * ((100vw - 768px) / 672)); padding-left:calc(14px + 46 * ((100vw - 768px) / 672));
        }
        @include media-breakpoint-up(xl-1440) {
            padding-right:60px; padding-left:60px;
        }
      }
    }

    .form-field {
      float:left;
      width:100%;
      margin-top:28px;

      @include media-breakpoint-up(xs-375) {
          margin-top:calc(28px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
          margin-top:42px;
      }

      &.mt-small {
        margin-top:28px!important;

        @include media-breakpoint-up(md) {
          margin-top:16px!important;
        }
      }

      label:not(.btn) {
        display:block; float:left;
        width:100%;
        font-size:12px; line-height:16px; color:color(white); font-weight:400; font-style:italic; letter-spacing:0.72px;
      }
      input {
        padding:6px 0 7px 0;
        border-bottom:1px solid color(grey-form);
        font-size:16px; line-height:22px; color:color(white); font-weight:700; letter-spacing:0.72px;

        @include media-breakpoint-up(md) {
          font-size:12px;
        }

        &[type="file"] {
          display:none;
        }
      }
      textarea {
        height:145px;
        padding:10px; margin-top:9px;
        border:1px solid color(grey-form);
        font-size:16px; line-height:22px; color:color(white); font-weight:700; letter-spacing:0.72px;
        resize:none;
        @include prefix(border-radius, 7px!important, webkit moz o ms);

        @include media-breakpoint-up(md) {
          font-size:12px;
        }
      }
      [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
          position:absolute; left:-9999px;
      }
      [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
          position:relative; top:0;
          display:inline-block;
          padding-left:35px; padding-right:0; margin:0;
          cursor:pointer;
          font-size:13px; line-height:20px; color:color(white); font-weight:400; font-style:normal;

          a {
            display:inline; float:none;
            width:auto;
            font-weight:700;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            background-image:linear-gradient(color(red), color(red));
            background-repeat:no-repeat;
            background-position:bottom left;
            background-size:100% 2px;

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(red);
              }
            }
          }
      }
      [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
          content:'';
          position:absolute; left:0; top:0;
          width:20px; height:20px;
          border:2px solid color(grey-light);
          background-color:transparent;
          @include prefix(border-radius, 2px, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
          content:$icon-az-checked;
          position:absolute; top:5px; left:5px;
          width:10px; height:10px;
          font-size:10px; line-height:10px; color:(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
          @include prefix(transform, scale(0,0), webkit moz o ms);
          @include prefix(transition, all .15s ease-out, webkit moz o ms);
      }
      [type="checkbox"]:checked + label:after {
          @include prefix(transform, scale(1,1), webkit moz o ms);
      }
      p.mandatory {
        font-size:13px; line-height:20px; color:color(red); font-weight:400; letter-spacing:0;
      }
      .btn.upload {
        float:left;

        @include media-breakpoint-up(sm) {
          float:right;
        }
      }

      .select2-container {
        float:left;
        width:100%!important;

        .select2-selection {
          height:auto;
          border:1px solid color(grey-form);
          background-color:color(black);
          @include prefix(border-radius, 100px, webkit moz o ms);

          .select2-selection__rendered {
            padding:14px 60px 14px 30px;
            font-size:16px; line-height:22px; color:color(white); font-weight:400; font-style:italic; letter-spacing:0.72px;

            @include media-breakpoint-up(md) {
              font-size:12px;
            }
          }
          .select2-selection__arrow {
            top:0; right:0;
            width:60px; height:100%;

            b {
              display:none;
            }
            &:after {
              content:$icon-az-arrow-down;
              position:absolute; right:30px; top:50%;
              width:7px; height:7px;
              font-size:7px; line-height:7px; color:color(white); font-family:'icomoon'!important; font-weight:normal; font-style:normal;
              @include prefix(transform, translateY(-50%), webkit moz o ms);
            }
          }
        }

        .select2-dropdown {
          margin-top:10px;
          border:0 none;
          @include prefix(border-radius, 6px, webkit moz o ms);
          background-color:color(white);
          overflow:hidden;

          .select2-results__options {
            padding:0;

            .select2-results__option {
              position:relative;
              padding:12px 12px 13px 12px;
              font-size:16px; line-height:20px; color:color(black); font-weight:400; font-style:italic; letter-spacing:0.72px; font-style:normal;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);

              @include media-breakpoint-up(md) {
                font-size:12px;
              }

              &:after {
                content:'';
                position:absolute; left:12px; bottom:0;
                width:calc(100% - 24px); height:1px;
                background-color:color(grey-light);
              }

              &:last-child {
                &:after {
                  content:none;
                }
              }
              &:first-child {
                display:none;
              }

              &.select2-results__option--selected {
                background-color:transparent;
              }
              &.select2-results__option--highlighted {
                color:color(red);
                background-color:transparent;
              }
            }
          }
        }

        &.fill {
          .select2-selection {
            .select2-selection__rendered {
              font-weight:700; font-style:normal;
            }
          }
        }
      }
    }
    .form-button {
      float:left;
      width:100%;
      margin-top:60px;
      text-align:center;

      @include media-breakpoint-up(xs-375) {
        margin-top:calc(60px + 40 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-top:100px;
      }
    }
  }
}
