.footer {
    display:block; float:left;
    width:100%;
    margin-top:68px; padding-bottom:72px; padding-left:12px; padding-right:12px;
    color:color(white);
    background-color:color(black);

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(68px + 38 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(lg) {
        padding-bottom:28px;
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:106px;
    }

    .footer-logo {
      float:left;
      height:68px;

      @include media-breakpoint-up(lg) {
        height:98px;
        margin-left:52px;
      }

      img {
          height:100%; width:auto;
      }
    }
    .footer-content {
      display:flex; flex-direction:column; align-items:flex-start;
      width:100%;
      margin-top:25px;

      @include media-breakpoint-up(sm) {
        flex-direction:row;
        margin-top:0;
      }

      > div {
        display:flex; flex-direction:column; align-items:center;
        width:100%;
        margin-bottom:42px;

        @include media-breakpoint-up(sm) {
          align-items:flex-start;
          width:auto;
        }
        @include media-breakpoint-up(md) {
          margin-bottom:0;
        }

        > div {
          display:flex; flex-direction:column; align-items:center;
          text-align:center;

          @include media-breakpoint-up(sm) {
            align-items:flex-start;
            margin-right:60px;
            text-align:left;
          }

          h6, .h6 {
            width:100%;

            @include media-breakpoint-up(sm) {
              width:auto;
            }
          }
          a, p {
            width:100%;
            font-size:13px; line-height:20px; letter-spacing:0.26px;

            @include media-breakpoint-up(xs-375) {
                font-size:calc(13px + 1 * ((100vw - 375px) / 1065)); line-height:calc(20px + 2 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(sm) {
              width:auto;
            }
            @include media-breakpoint-up(lg) {
                letter-spacing:0.28px;
            }
            @include media-breakpoint-up(xl-1440) {
                font-size:14px; line-height:22px;
            }
          }
          p {
            margin-top:6px;
          }
          a {
            margin-top:10px;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              &:hover {
                color:color(red);
              }
            }
          }
        }
        .btn {
          margin-top:18px;

          @include media-breakpoint-up(xs-375) {
              margin-top:calc(18px + 2 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
              margin-top:20px;
          }
        }
      }
    }
    .footer-bottom {
      display:flex; float:left; flex-direction:column; align-items:center; justify-content:center;
      width:100%;
      margin-top:2px;

      @include media-breakpoint-up(sm) {
        flex-direction:row; justify-content:flex-end;
      }
      @include media-breakpoint-up(md) {
        margin-top:26px;
      }

      > * {
        width:auto;
        margin-top:10px;
        font-size:12px; line-height:22px; letter-spacing:0.24px;

        @include media-breakpoint-up(sm) {
          margin-left:60px; margin-top:0;
        }

        &:first-child {
          margin-left:0;
        }
      }
      a {
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          &:hover {
            color:color(red);
          }
        }
      }
    }
}
