/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
  }
  #{$property}: $value;
}

/* --- */

$font_poppins: 'Poppins', sans-serif;
// Regular: 400 | Medium: 500 | Semi-bold: 600 | Bold: 700

.font-poppins {
  font-family:$font_poppins!important; font-weight:400;
}

html, body {
  color:color(white);
  font-family:$font_poppins; font-weight:400;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  font-weight:600;
}

h1, .h1 {
  font-size:30px; line-height:42px;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(30px + 20 * ((100vw - 375px) / 1065)); line-height:calc(42px + 20 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:50px; line-height:62px;
  }
}
h2, .h2 {
  font-size:26px; line-height:36px; letter-spacing:0.78px;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(26px + 18 * ((100vw - 375px) / 1065)); line-height:calc(36px + 18 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
      letter-spacing:1.32px;
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:44px; line-height:54px;
  }
}
h3, .h3 {
  font-size:18px; line-height:32px; color:color(red); font-weight:500;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(18px + 10 * ((100vw - 375px) / 1065)); line-height:calc(32px + 12 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:28px; line-height:44px;
  }
}
h4, .h4 {
  font-size:16px; line-height:22px; letter-spacing:0;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(16px + 8 * ((100vw - 375px) / 1065)); line-height:calc(22px + 10 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:24px; line-height:32px;
  }
}
h5, .h5 {
  font-size:13px; line-height:25px; letter-spacing:0.78px; font-weight:600; text-transform:uppercase;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(13px + 1 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
      letter-spacing:0.84px; font-weight:700;
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:14px;
  }
}
h6, .h6 {
  font-size:16px; line-height:22px; letter-spacing:0.4px;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(22px + 6 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:20px; line-height:28px;
  }
}

p {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
  font-size:13px; line-height:24px; letter-spacing:0.78px;

  @include media-breakpoint-up(xs-375) {
      font-size:calc(13px + 2 * ((100vw - 375px) / 1065)); line-height:calc(24px + 8 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(lg) {
      letter-spacing:0.9px;
  }
  @include media-breakpoint-up(xl-1440) {
      font-size:15px; line-height:32px;
  }

  strong {
      font-weight:700;
  }
  a {
    display:inline; float:none;
    width:auto;
    font-weight:700;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    background-image:linear-gradient(color(red), color(red));
    background-repeat:no-repeat;
    background-position:bottom left;
    background-size:100% 2px;

    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(red);
      }
    }
  }
}
a {
  &.phone {
    @include media-breakpoint-up(lg) {
      cursor:default;
      pointer-events:none;
    }
  }
}
