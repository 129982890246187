.teaser {
  display:block; float:left;
  width:100%;

  a {
    position:relative;
    display:block; float:left;
    width:100%;

    picture {
      position:relative;
      z-index:1;
    }
    &:before {
      content:none;
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      z-index:2;
      background:rgb(0,0,0);
      background:linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.3) 100%);
      opacity:0.6;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(md) {
        content:'';
      }
    }
    .teaser-content {
      float:left;
      width:100%;
      margin-top:12px;
      z-index:3;
      text-align:center;

      @include media-breakpoint-up(md) {
        position:absolute; left:0; top:0;
        margin-top:0;
        padding:calc(28px + 18 * ((100vw - 768px) / 672)) calc(24px + 14 * ((100vw - 768px) / 672));
        text-align:left;
      }
      @include media-breakpoint-up(xl-1440) {
        padding:46px 38px;
      }

      h4, .h4 {
        text-transform:uppercase; font-weight:500; color:color(white)!important;
      }
      p {
        margin-top:4px;
        font-size:12px; line-height:18px; letter-spacing:0; color:color(white)!important;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(4px + 6 * ((100vw - 375px) / 1065));
          font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:10px;
          font-size:14px; line-height:23px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover {
        color:color(white);

        &:before {
          background:linear-gradient(145deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 100%);
          opacity:0.8;
        }
      }
    }
  }
}

.teaser-product {
  display:block; float:left;
  width:100%;

  a {
    position:relative;
    display:block; float:left;
    width:100%;

    picture {
      position:relative;
      z-index:1;
    }
    &:before {
      content:none;
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      z-index:2;
      background:rgb(0,0,0);
      background:linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.8) 45%, rgba(0,0,0,0.3) 100%);
      opacity:0;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(lg) {
        content:'';
      }
    }
    .teaser-content {
      display:flex; flex-direction:column; align-items:center;
      float:left;
      width:100%;
      margin-top:12px;
      text-align:center;
      z-index:3;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      @include media-breakpoint-up(xl) {
        position:absolute; left:0; top:50%;
        padding:28px; margin-top:0;
        opacity:0;
        @include prefix(transform, translateY(-40%), webkit moz o ms);
      }

      h5, .h5 {
        display:inline; float:none;
        width:auto;
        color:color(white);

        @include media-breakpoint-up(xl) {
          background-image:linear-gradient(color(white), color(white));
          background-repeat:no-repeat;
          background-position:bottom left;
          background-size:100% 3px;
        }
      }
      p {
        display:none;
        margin-top:20px;
        font-size:14px; line-height:22px; letter-spacing:0; color:color(white);

        @include media-breakpoint-up(xs-375) {
            font-size:calc(14px + 0 * ((100vw - 375px) / 1065)); line-height:calc(22px + 0 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl) {
          display:block;
        }
        @include media-breakpoint-up(xl-1440) {
            font-size:14px; line-height:22px;
        }
      }
      .btn {
        display:none;
        margin-top:26px;

        @include media-breakpoint-up(xl) {
          display:block;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      &:hover {
        color:color(white);

        &:before {
          opacity:0.9;
        }
        .teaser-content {
          opacity:1;
          @include prefix(transform, translateY(-50%), webkit moz o ms);
        }
      }
    }
  }
}

.teaser-info-wrapper {
  margin-top:-15px;

  > div {
    margin-top:15px;
  }
}
.teaser-info {
  position:relative;
  display:block; float:left;
  width:84%; height:100%;
  padding:12px 8px 20px 76px; margin-left:8%; margin-right:8%;
  background-color:color(red);

  @include media-breakpoint-up(sm) {
    width:100%;
    margin-left:0; margin-right:0;
    padding:76px 12px 20px 12px;
    text-align:center;
  }
  @include media-breakpoint-up(lg) {
    padding:calc(12px + 14 * ((100vw - 992px) / 448)) calc(8px + 6 * ((100vw - 992px) / 448)) calc(20px + 8 * ((100vw - 992px) / 448)) calc(76px + 22 * ((100vw - 992px) / 448));
    text-align:left;
  }
  @include media-breakpoint-up(xl-1440) {
    padding:26px 14px 28px 98px;
  }

  i {
    position:absolute; left:18px; top:28px;
    width:42px; height:42px;
    font-size:42px; line-height:42px; color:color(white);

    @include media-breakpoint-up(xs-375) {
      width:calc(42px + 4 * ((100vw - 375px) / 1065)); height:calc(42px + 4 * ((100vw - 375px) / 1065));
      font-size:calc(42px + 4 * ((100vw - 375px) / 1065)); line-height:calc(42px + 4 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(sm) {
      left:50%; top:18px;
      @include prefix(transform, translateX(-50%), webkit moz o ms);
    }
    @include media-breakpoint-up(lg) {
      left:calc(18px + 8 * ((100vw - 992px) / 448)); top:calc(28px + 22 * ((100vw - 992px) / 448));
      @include prefix(transform, translateX(0%), webkit moz o ms);
    }
    @include media-breakpoint-up(xl-1440) {
      left:26px; top:50px;
      width:46px; height:46px;
      font-size:46px; line-height:46px;
    }
  }
  p {
    margin-top:5px;
    font-size:12px; line-height:20px; letter-spacing:0;

    @include media-breakpoint-up(xs-375) {
      margin-top:calc(4px + 8 * ((100vw - 375px) / 1065));
      font-size:calc(12px + 2 * ((100vw - 375px) / 1065)); line-height:calc(20px + 3 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-top:12px;
      font-size:14px; line-height:23px;
    }
  }
}
