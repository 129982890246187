$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/icomoon.ttf?edf3qq') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?edf3qq') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?edf3qq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

[class^="icon-az-"], [class*=" icon-az-"] {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-az-paperwork: "\e911";
$icon-az-arrow-line-right: "\e90c";
$icon-az-arrow-line-left: "\e90e";
$icon-az-plus: "\e90d";
$icon-az-checked: "\e90f";
$icon-az-all-photos: "\e910";
$icon-az-close: "\e90b";
$icon-az-arrow-down: "\e900";
$icon-az-arrow-left: "\e901";
$icon-az-arrow-right: "\e902";
$icon-az-arrow-up: "\e903";
$icon-az-download: "\e904";
$icon-az-menu-mobile: "\e905";
$icon-az-product-list: "\e906";
$icon-az-quality: "\e907";
$icon-az-search: "\e908";
$icon-az-selection: "\e909";
$icon-az-service: "\e90a";

.icon-az-paperwork {
  &:before {
    content: $icon-az-paperwork;
  }
}
.icon-az-arrow-line-right {
  &:before {
    content: $icon-az-arrow-line-right;
  }
}
.icon-az-arrow-line-left {
  &:before {
    content: $icon-az-arrow-line-left;
  }
}
.icon-az-plus {
  &:before {
    content: $icon-az-plus;
  }
}
.icon-az-checked {
  &:before {
    content: $icon-az-checked;
  }
}
.icon-az-all-photos {
  &:before {
    content: $icon-az-all-photos;
  }
}
.icon-az-close {
  &:before {
    content: $icon-az-close;
  }
}
.icon-az-arrow-down {
  &:before {
    content: $icon-az-arrow-down;
  }
}
.icon-az-arrow-left {
  &:before {
    content: $icon-az-arrow-left;
  }
}
.icon-az-arrow-right {
  &:before {
    content: $icon-az-arrow-right;
  }
}
.icon-az-arrow-up {
  &:before {
    content: $icon-az-arrow-up;
  }
}
.icon-az-download {
  &:before {
    content: $icon-az-download;
  }
}
.icon-az-menu-mobile {
  &:before {
    content: $icon-az-menu-mobile;
  }
}
.icon-az-product-list {
  &:before {
    content: $icon-az-product-list;
  }
}
.icon-az-quality {
  &:before {
    content: $icon-az-quality;
  }
}
.icon-az-search {
  &:before {
    content: $icon-az-search;
  }
}
.icon-az-selection {
  &:before {
    content: $icon-az-selection;
  }
}
.icon-az-service {
  &:before {
    content: $icon-az-service;
  }
}
