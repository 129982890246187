// Color variables

$color-list: (
    white:#FFFFFF,
    black:#0B0B0B,
    red:#D90916,
    dark:#262626,
    grey-dark:#505050,
    grey-form:#4C4C4C,
    grey-scroll:#606060,
    grey-med:#707070,
    grey:#777777,
    grey-light:#CECECE,
    grey-lightest:#AAAAAA
);

// Color class loop

@each $color, $value in $color-list {
    .txt-#{$color} { color:$value!important; }
    .bkg-#{$color} { background-color:$value!important; }
}

// Color var function

@function color($color) {
    @return map-get($color-list, $color);
}
