.product-category {
  float:left;
  width:100%;

  .teaser-product-category {
    display:block; float:left;
    width:100%;
    margin-top:34px;

    @include media-breakpoint-up(xs-375) {
        margin-top:calc(34px + 12 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        margin-top:46px;
    }

    &:first-child {
      margin-top:0;
    }

    a {
      position:relative;
      display:flex; flex-direction:column; align-items:center;
      width:100%;
      @include prefix(transition, all .2s ease-out, webkit moz o ms);

      @include media-breakpoint-up(sm) {
        flex-direction:row;
      }

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        border:1px solid color(white);
        z-index:1;
        @include prefix(transition, all .2s ease-out, webkit moz o ms);
      }
      &:after {
        content:none;
        position:absolute; left:0; top:0;
        width:calc(50% + 8px); height:100%;
        background-color:color(black);
        opacity:0;
        z-index:3;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          content:'';
        }
      }
      > picture {
        position:relative;
        display:block; float:left;
        width:100%;
        z-index:2;

        @include media-breakpoint-up(sm) {
          width:calc(50% + 8px);
        }
      }
      > div {
        position:relative;
        float:left;
        width:100%;
        padding:24px;
        z-index:2;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(sm) {
          width:calc(50% - 8px);
          padding:calc(24px + 18 * ((100vw - 576px) / 416)) calc(24px + 32 * ((100vw - 576px) / 416)) calc(24px + 18 * ((100vw - 576px) / 416)) calc(24px + 18 * ((100vw - 576px) / 416));
        }
        @include media-breakpoint-up(lg) {
          padding:42px 56px 42px 42px;
        }

        h4, .h4 {
          text-transform:uppercase; font-weight:500; color:color(white); text-align:center;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(sm) {
            text-align:left;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:after {
            opacity:0.3;
          }
          > div {
            padding:42px 42px 42px 56px;
          }
        }
      }
    }
  }
}

.product-list {
  margin-bottom:-32px;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(-32px - 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:-72px;
  }

  &.category {
    margin-bottom:-32px;

    @include media-breakpoint-up(xs-375) {
      margin-bottom:calc(-32px - 14 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      margin-bottom:-46px;
    }

    .product {
      margin-bottom:32px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(32px + 14 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:46px;
      }
    }
  }

  &.detail {
    position:relative;
    margin-bottom:0;
    margin-right:-6px; margin-left:-6px;

    @include media-breakpoint-up(lg) {
      margin-right:-14px; margin-left:-14px;
    }

    > div {
      position:relative;
      z-index:1;
      padding-right:6px; padding-left:6px;

      @include media-breakpoint-up(lg) {
        padding-right:14px; padding-left:14px;
      }

      &.product-list-all {
        width:100%;
        padding:0 6px; margin-bottom:18px;
        z-index:2;

        @include media-breakpoint-up(lg) {
          position:absolute; right:14px; top:0;
          padding:0; margin-bottom:0;
          width:0; height:calc(124px + 36 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
            height:160px;
        }

        > a {
          display:flex; flex-direction:column; align-items:center;
          float:right;
          z-index:2;

          @include media-breakpoint-up(lg) {
            position:absolute; top:20px; right:0;
            @include prefix(transform, translateX(50%), webkit moz o ms);
          }

          p {
            display:none;
            width:46px;
            margin-bottom:7px;
            font-size:11px; line-height:15px; letter-spacing:0.55px; text-transform:uppercase; color:color(white); text-align:center;

            @include media-breakpoint-up(lg) {
              display:block;
            }
          }
          i {
            position:relative;
            width:34px; height:34px;
            font-size:12px; line-height:34px; color:color(white); text-align:center;
            background-color:color(black);
            @include prefix(border-radius, 50%, webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            @include media-breakpoint-up(lg) {
              width:44px; height:44px;
              font-size:18px; line-height:44px;
            }

            &:after {
              content:'';
              position:absolute; left:0; top:0;
              width:100%; height:100%;
              border:2px solid color(white);
              @include prefix(border-radius, 50%, webkit moz o ms);
            }
          }
          @include media-breakpoint-up(lg) {
            &:hover {
              i {
                color:color(red);
                background:color(white);
              }
            }
          }
        }
        &:before {
          content:none;
          position:absolute; right:0; top:0;
          width:78px; height:100%;
          background:rgb(11,11,11);
          background:linear-gradient(270deg, rgba(11,11,11,1) 0%, rgba(11,11,11,0) 100%);

          @include media-breakpoint-up(lg) {
            content:'';
          }
        }
      }
    }

    .product {
      margin-bottom:18px;

      @include media-breakpoint-up(xs-375) {
        margin-bottom:calc(18px + 8 * ((100vw - 375px) / 1065));
      }
      @include media-breakpoint-up(xl-1440) {
        margin-bottom:26px;
      }
    }

    .product-anchor {
      display:block;
      //margin-top:-152px;
      @include prefix(transform, translateY(-152px), webkit moz o ms);
    }

    &.close {
      @include media-breakpoint-down(lg) {
        .product-slider {
          margin-bottom:0;
        }
        .product.thumb:not(.top) {
          display:none;
        }
      }
    }
  }
}

article.product {
  display:block; float:left;
  width:100%;
  margin-bottom:32px;

  @include media-breakpoint-up(xs-375) {
    margin-bottom:calc(32px + 40 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    margin-bottom:72px;
  }

  a {
    display:block; float:left;
    width:100%;

    .image {
      position:relative;
      float:left;
      width:100%;

      picture {
        position:relative;
        z-index:1;
      }
      &:before {
        content:none;
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-color:color(black);
        opacity:0;
        z-index:2;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          content:'';
        }
      }
      span {
        position:absolute; right:9px; top:9px;
        width:40px; height:40px;
        background-color:color(black);
        z-index:3;
        @include prefix(border-radius, 50%, webkit moz o ms);

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:2px solid color(white);
          z-index:1;
          @include prefix(border-radius, 50%, webkit moz o ms);
        }
        i {
          position:absolute; left:50%; top:50%;
          width:18px; height:18px;
          font-size:18px; line-height:18px; color:color(white);
          z-index:2;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }
      }
    }
    .content {
      position:relative;
      float:left;
      width:100%;
      margin-top:10px;

      h4, .h4, h5, .h5, h6, .h6 {
        padding-left:0; padding-right:14px;
      }
      h4, .h4 {
        text-transform:uppercase; font-weight:500; color:color(white); text-align:left; letter-spacing:0.48px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }
      h5, .h5 {
        margin-top:2px;
        font-size:16px; line-height:22px; letter-spacing:0.36px; text-transform:inherit; font-weight:500; color:color(white); text-align:left;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          font-size:calc(16px + 2 * ((100vw - 375px) / 1065)); line-height:calc(22px + 6 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:18px; line-height:28px;
        }
      }
      h6, .h6 {
        margin-top:-4px;
        font-size:10px; line-height:16px; letter-spacing:0.26px; text-transform:inherit; font-weight:500; color:color(white); text-align:left;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(-4px + 4 * ((100vw - 375px) / 1065));
          font-size:calc(10px + 3 * ((100vw - 375px) / 1065)); line-height:calc(16px + 3 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:0;
          font-size:13px; line-height:19px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .image {
          &:before {
            opacity:0.4;
          }
        }
        .content {
          h4, .h4, h5, .h5, h6, .h6 {
            padding-left:14px; padding-right:0;
          }
        }
      }
    }
  }

  &.thumb {
    a {
      .image {
        position:relative;
        height:auto;

        &:after {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:3px solid color(white);
          opacity:0;
          z-index:3;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        picture {
          display:flex; justify-content:center; align-items:center;
          height:100%;
          text-align:center;

          img {
            width:100%; height:100%; object-fit:contain;
          }
        }
      }
      .content {
        display:flex; flex-direction:row; align-items:flex-start;
        padding-left:0; padding-right:14px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        h4, .h4, h5, .h5, h6, .h6 {
          width:auto;
          margin-top:0;
          padding-top:4px; padding-bottom:4px;
          padding-left:0!important; padding-right:0!important;
          line-height:14px!important;
        }
        > span {
          flex-shrink:0;
          display:inline-block; float:left;
          width:22px; height:22px;
          margin-right:10px;
          border:1px solid color(white);
          font-size:10px; line-height:20px; letter-spacing:0; text-transform:inherit; font-weight:500; color:color(white); text-align:center;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);

          @include media-breakpoint-up(xs-375) {
            font-size:calc(10px + 3 * ((100vw - 375px) / 1065)); line-height:calc(20px + 1 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            font-size:13px; line-height:21px;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          .content {
            padding-left:14px; padding-right:0;
          }
        }
      }
    }

    &.active {
      a {
        .image {
          &:before {
            opacity:0.4;
          }
          &:after {
            opacity:1;
          }
        }
      }
    }

    &.top {
      display:none;

      @include media-breakpoint-up(lg) {
        display:block;
      }
    }
  }
}

.product-nav {
  display:flex; flex-direction:row; justify-content:space-between; align-items:flex-start; flex-wrap:wrap;
  width:100%;
  padding:32px 0;
  border-top:1px solid color(grey-med); border-bottom:1px solid color(grey-med);

  @include media-breakpoint-up(xs-375) {
    padding:calc(32px + 14 * ((100vw - 375px) / 1065)) 0;
  }
  @include media-breakpoint-up(md) {
    align-items:center;
  }
  @include media-breakpoint-up(xl-1440) {
    padding:46px 0;
  }

  > div {
    width:50%;

    @include media-breakpoint-up(md) {
      width:28%;
    }

    a {
      display:flex; flex-direction:column;
      color:color(white);

      span {
        position:relative;
        display:block;
        width:56px; height:56px;
        margin:0;
        opacity:1;
        cursor:pointer;
        z-index:1;

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          border:1px solid color(white);
          z-index:1;
          @include prefix(border-radius, 50%, webkit moz o ms);
          @include prefix(transition, all .15s ease-out, webkit moz o ms);
        }
        &:after {
          content:none;
        }
        i {
          position:absolute; left:50%; top:50%;
          width:22px; height:22px;
          font-size:22px; line-height:22px; color:color(white);
          z-index:2;
          @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
        }
      }
      p {
        width:auto;
        margin-top:14px;
        font-size:13px; line-height:18px; letter-spacing:0.96px; text-transform:uppercase; font-weight:700;

        @include media-breakpoint-up(xs-375) {
          margin-top:calc(14px + 12 * ((100vw - 375px) / 1065));
          font-size:calc(13px + 1 * ((100vw - 375px) / 1065)); line-height:calc(18px + 2 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(lg) {
          letter-spacing:1.26px;
        }
        @include media-breakpoint-up(xl-1440) {
          margin-top:26px;
          font-size:14px; line-height:20px;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          span {
            &:before {
              border:3px solid color(white);
            }
          }
        }
      }
    }

    &.prev {
      padding-right:30px;

      @include media-breakpoint-up(md) {
        padding-right:0;
        order:1;
      }
      a {
        align-items:flex-end;
      }
    }
    &.next {
      padding-left:30px;

      @include media-breakpoint-up(md) {
        padding-left:0;
        order:3;
      }
      a {
        align-items:flex-start;
      }
    }
  }
  > h4 {
    width:100%;
    margin-bottom:20px;
    text-transform:uppercase; font-weight:500; letter-spacing:0; text-align:center;

    @include media-breakpoint-up(md) {
      width:34%;
      margin-bottom:0;
      order:2;
    }
    @include media-breakpoint-up(lg) {
      width:20%;
    }
  }
}
