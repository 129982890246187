.hero-slider-wrapper {
  margin-top:28px;

  .hero-slider {
    position:relative;

    .swiper {
      position:relative;
      display:block; float:left;
      width:100%;
      z-index:2;

      .swiper-slide {
        position:relative;

        picture {
          position:relative;
          width:100%;
          z-index:1;

          @include media-breakpoint-up(md) {
            width:calc(100% - (((100% - 200px) / 11) + 20px));
            margin-left:calc(((100% - 200px) / 11) + 20px);
          }

          &:before, &:after {
            content:'';
            position:absolute; top:0;
            width:100%; height:100%;
            opacity:0.5;

            @include media-breakpoint-up(md) {
              width:54%;
              opacity:0.7;
            }
          }
          &:before {
            right:0;
            background:rgb(0,0,0);
            background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);

            @include media-breakpoint-up(md) {
              background:rgb(0,0,0);
              background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            }
          }
          &:after {
            content:none;
            left:0;
            background:rgb(0,0,0);
            background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);

            @include media-breakpoint-up(md) {
              content:'';
            }
          }
        }
        .slide-content {
          float:left;
          width:100%;
          margin-top:50px;
          z-index:2;

          @include media-breakpoint-up(md) {
            position:absolute; left:calc(22px + 30 * ((100vw - 768px) / 672)); top:calc(35px + 20 * ((100vw - 768px) / 672));
            width:74%;
            margin-top:0;
          }
          @include media-breakpoint-up(lg) {
            width:58%;
          }
          @include media-breakpoint-up(xl) {
            width:44%;
          }
          @include media-breakpoint-up(xl-1440) {
            left:52px; top:55px;
          }

          p {
            margin-bottom:8px;
            font-size:13px; line-height:18px; letter-spacing:1.04px; text-transform:uppercase;

            @include media-breakpoint-up(xs-375) {
              font-size:calc(13px + 3 * ((100vw - 375px) / 1065)); line-height:calc(18px + 4 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(lg) {
              letter-spacing:1.28px;
            }
            @include media-breakpoint-up(xl-1440) {
              font-size:16px; line-height:22px;
            }
          }
          h2, .h2 {
            font-size:30px; line-height:42px;

            @include media-breakpoint-up(xs-375) {
                font-size:calc(30px + 20 * ((100vw - 375px) / 1065)); line-height:calc(42px + 20 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
                font-size:50px; line-height:62px;
            }
          }
          .btn {
            margin-top:28px;

            @include media-breakpoint-up(xs-375) {
                margin-top:calc(28px + 12 * ((100vw - 375px) / 1065));
            }
            @include media-breakpoint-up(xl-1440) {
                margin-top:40px;
            }
          }
        }
      }
    }

    .swiper-pagination {
      position:absolute; left:0; top:0;
      width:100%;
      z-index:1;

      @include media-breakpoint-up(md) {
        left:calc(32px + 30 * ((100vw - 768px) / 672)); bottom:calc(25px + 90 * ((100vw - 768px) / 672)); top:inherit;
        width:auto;
      }
      @include media-breakpoint-up(xl-1440) {
        left:62px; bottom:115px;
      }
    }

    &.pag-load {
      .swiper {
        z-index:1;
      }
      .swiper-pagination {
        z-index:2;
      }
    }
  }
}

.teaser-slider {
  position:relative;

  .swiper {
    position:relative;
    display:block; float:left;
    width:calc(100% + 20px);
    margin-left:-10px; margin-right:-10px; margin-top:26px;

    .swiper-slide {
      position:relative;
      padding:0 10px;
    }
  }

  .swiper-pagination {
    position:relative; left:inherit; bottom:inherit;
    float:left;
    margin-top:34px;

    @include media-breakpoint-up(md) {
      margin-top:15px;
    }
  }
}

.teaser-product-slider {
  position:relative;
  padding-left:50px;

  @include media-breakpoint-up(md) {
    margin-top:-30px;
  }

  .swiper {
    position:relative;
    display:block; float:left;
    width:calc(100% + 20px);
    margin-left:-10px; margin-right:-10px;

    .swiper-slide {
      position:relative;
      padding:0;

      .teaser-product {
        width:100%;
        padding:0 16%; margin-top:34px;

        @include media-breakpoint-up(sm) {
          width:50%;
          padding:0 10px;
        }
        @include media-breakpoint-up(md) {
          margin-top:30px;
        }
      }
    }
  }

  .swiper-pagination {
    position:relative; left:inherit; bottom:inherit;
    float:left;
    margin-top:34px;

    @include media-breakpoint-up(md) {
      margin-top:30px;
    }
  }
}

.swiper-pagination {
  height:8px;
  font-size:0;

  @include media-breakpoint-up(xs-375) {
      height:calc(8px + 2 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
      height:10px;
  }

  .swiper-pagination-bullet {
    position:relative;
    width:8px; height:8px;
    margin:0 5px!important;
    opacity:1;
    background-color:transparent;

    @include media-breakpoint-up(xs-375) {
        width:calc(8px + 2 * ((100vw - 375px) / 1065)); height:calc(8px + 2 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
        width:10px; height:10px;
    }

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:1px solid color(white);
      @include prefix(border-radius, 50%, webkit moz o ms);
      @include prefix(transition, all .2s ease-out, webkit moz o ms);
    }

    &:first-child() {
      margin-left:0!important;
    }
    &:last-child() {
      margin-right:0!important;
    }

    &.swiper-pagination-bullet-active {
      background-color:color(red);

      &:before {
        border:1px solid color(red)!important;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        &:before {
          border:5px solid color(white);
        }
      }
    }
  }
}

.product-slider {
  position:relative;
  float:left;
  width:100%;
  margin:25px 0 50px 0;

  @include media-breakpoint-up(xs-375) {
    margin:25px 0 calc(30px + 20 * ((100vw - 375px) / 1065)) 0;
  }
  @include media-breakpoint-up(md) {
    padding:0 8%;
  }
  @include media-breakpoint-up(xl-1440) {
    margin:25px 0 50px 0;
  }

  .swiper {
    position:relative;
    display:block; float:left;
    width:100%;

    .swiper-slide {
      .image {
        height:auto/*246px*/;

        /*@include media-breakpoint-up(xs-375) {
            height:calc(246px + 82 * ((100vw - 375px) / 105));
        }
        @include media-breakpoint-up(xs-480) {
            height:calc(328px + 128 * ((100vw - 480px) / 288));
        }
        @include media-breakpoint-up(md) {
            height:calc(456px + 40 * ((100vw - 768px) / 224));
        }
        @include media-breakpoint-up(lg) {
            height:calc(496px + 162 * ((100vw - 992px) / 448));
        }
        @include media-breakpoint-up(xl-1440) {
            height:658px;
        }*/

        picture {
          display:flex; justify-content:center; align-items:center;
          height:100%;
          text-align:center;

          img {
            width:100%; height:100%; object-fit:contain;
          }
        }
      }
      .caption {
        float:left;
        width:100%;
        margin-top:22px; padding-right:16%;

        @include media-breakpoint-up(lg) {
          padding-right:20%;
        }

        h4, .h4 {
          font-size:16px; line-height:22px; letter-spacing:0;

          @include media-breakpoint-up(xs-375) {
              font-size:calc(16px + 6 * ((100vw - 375px) / 1065)); line-height:calc(22px + 10 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
              font-size:22px; line-height:32px;
          }
        }
        p {
          margin-top:5px;
          font-size:11px; line-height:18px; letter-spacing:0;

          @include media-breakpoint-up(xs-375) {
            margin-top:calc(5px + 2 * ((100vw - 375px) / 1065));
            font-size:calc(11px + 2 * ((100vw - 375px) / 1065)); line-height:calc(18px + 6 * ((100vw - 375px) / 1065));
          }
          @include media-breakpoint-up(xl-1440) {
            margin-top:7px;
            font-size:13px; line-height:24px;
          }
        }
      }
    }
  }

  .swiper-button-prev-wrapper, .swiper-button-next-wrapper {
    position:absolute; top:0;
    display:none;
    width:52px; height:52px;
    margin-top:-26px;
    opacity:0;

    @include media-breakpoint-up(xl) {
      display:block;
    }

    > a {
      position:absolute; left:0; top:0;
      display:none;
      width:100%; height:100%;
      z-index:2;
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .swiper-button-prev, .swiper-button-next {
          &:before {
            border:3px solid color(white);
          }
        }
      }
    }

    .swiper-button-prev.swiper-button-disabled + a, .swiper-button-next.swiper-button-disabled + a {
      display:block;
    }
  }
  .swiper-button-prev-wrapper {
    left:0;
    @include prefix(transform, translateX(-50%), webkit moz o ms);
  }
  .swiper-button-next-wrapper {
    right:0;
    @include prefix(transform, translateX(50%), webkit moz o ms);
  }
  .swiper-button-prev, .swiper-button-next {
    top:0; left:0; right:inherit; bottom:inherit;
    width:100%; height:100%;
    margin:0;
    opacity:1;
    cursor:pointer;
    z-index:1;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      border:1px solid color(white);
      z-index:1;
      @include prefix(border-radius, 50%, webkit moz o ms);
      @include prefix(transition, all .15s ease-out, webkit moz o ms);
    }
    &:after {
      content:none;
    }
    i {
      position:absolute; left:50%; top:50%;
      width:20px; height:20px;
      font-size:20px; line-height:20px; color:color(white);
      z-index:2;
      @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
    }
  }

  &.nav-load {
    .swiper-button-prev-wrapper, .swiper-button-next-wrapper {
      opacity:1;
    }
  }
}

.product-slider-mobile {
  position:relative;
  float:left;
  width:calc(100% + 12px);
  margin:0 -6px 30px -6px;

  .swiper {
    position:relative;
    display:block; float:left;
    width:100%;

    .swiper-slide {
      padding:0 6px;

      .product {
        margin-bottom:0;
      }
    }
  }

  .swiper-scrollbar {
      position:relative;
      float:left;
      width:100%; height:3px;
      margin-top:20px;
      background-color:color(grey-scroll)!important;
      opacity:1!important;

      .swiper-scrollbar-drag {
          background-color:color(white)!important;
          opacity:1!important;
      }
  }
}
